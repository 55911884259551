.App {
  
  /* background-color: #00aaaa; */
}

.contents-wrapper {
  padding-top: 180px;
  min-width: 720px;
  /* max-width: 1280px; */
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.App .app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
}
