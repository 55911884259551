.sectionHeader {
    color: #333;
    position: relative;
    padding: 1.5rem;
    text-align: center;
    margin-bottom: 1em;
}
.sectionHeader:before {
    position: absolute;
    bottom: 0px;
    left: calc(50% - 30px);
    width: 60px;
    height: 5px;
    content: '';
    border-radius: 3px;
    background: #02BE50;
}

.btn-box {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.btn-box .contents-btn {
    display: inline-block;
    padding: 1em 2em;
    width: calc((100% / 2) - 10px);
    text-decoration: none;
    background: #f7f7f7;
    font-size: 20px;
    border-left: solid 6px #07BE4F;
    color: #01a041;
    border-radius: 10px;
    font-weight: bold;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);
    margin-bottom: 10px;
}
.btn-box .contents-btn:hover {
    border-left: solid 6px #ff8d2f;
    color: #ee7917;
}
.btn-box .contents-btn:active {
    box-shadow: inset 0 0 2px rgba(128, 128, 128, 0.1);
    transform: translateY(2px);
}