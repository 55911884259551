.markdown-wrapper {
    color: #333333;
}
.markdown-wrapper .sharebuttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
}
.markdown-wrapper .sharebuttons .updateTime {
    font-size: 12px;
    font-weight: bold;
}
.markdown-wrapper .title {
    position: relative;
    padding: 1rem 0;
    border-bottom: 6px solid #094;
    margin-bottom: 0.2em;
}
.markdown-wrapper .title:before {
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 20%;
    height: 6px;
    content: '';
    background: #00cc5b;
}
.markdown-wrapper h1:not(.title) {
    padding: 0.3em 0.5em;/*文字周りの余白*/
    color: #333333;/*文字色*/
    background: #f5fff4;/*背景色*/
    border-left: solid 5px #00cc5b;/*左線（実線 太さ 色）*/
    margin-top: 10pt;
    margin-bottom: 0.3em;
    font-size: 20pt;
}
.markdown-wrapper h2 {
    padding: 0.3em 0.5em;/*文字周りの余白*/
    color: #333333;/*文字色*/
    background: #fffbf4;/*背景色*/
    border-left: solid 5px #fe9b22;/*左線（実線 太さ 色）*/
    margin: 0.2em 0;
    font-size: 17pt;
}
.markdown-wrapper h3 {
    padding: 0.3em 0.5em;/*文字周りの余白*/
    color: #333333;/*文字色*/
    background: #f4f8ff;/*背景色*/
    border-left: solid 5px #2255fe;/*左線（実線 太さ 色）*/
    margin: 0.2em 0;
    font-size: 14pt;
}

.markdown-wrapper > ol, .markdown-wrapper > ul {
    padding: 1em 2em;
}

li > ul, li > ol {
    padding: 0.2em 2em;
}
ul > ol, ul > ul {
    padding: 0.5em 2em;
}

.markdown-wrapper blockquote {
    margin: 0;
    margin-bottom: 16px;
    margin-top: 0;
    border-left: .25em solid #dfe2e5;
    color: #6a737d;
    padding: 0.5em 1em;
    margin: 0.5em 0;
}
.markdown-wrapper blockquote>:first-child {
    margin-top: 0;
}
.markdown-wrapper blockquote>:last-child {
    margin-bottom: 0;
}