.sectionHeader {
    color: #333;
    position: relative;
    padding: 1.5rem;
    text-align: center;
    margin-bottom: 1em;
}
.sectionHeader:before {
    position: absolute;
    bottom: 0px;
    left: calc(50% - 30px);
    width: 60px;
    height: 5px;
    content: '';
    border-radius: 3px;
    background: #02BE50;
}

.thanksBtnBox > .backbtn {
    margin-top: 3em;
    position: relative;
    display: inline-block;
    font-weight: bold;
    padding: 0.25em 0.5em;
    text-decoration: none;
    color: #00BCD4;
    background: #ECECEC;
    transition: .4s;
}
.thanksBtnBox > .backbtn:hover {
    background: #00bcd4;
    color: white;
}